<template>
    
    <tabla-datos :botones="true" :columnas="columnas" :datos="expedientes" :filtros="filtros" :filtrosBuscador="filtrosBuscador" :total="total" @quieroDatos="obtenerExpedientes($event)" >
    </tabla-datos>
</template>   
<script>
   import { PwgsApi } from '../../../services/PwgsApi';
   import TablaDatos from '../../TablaDatos.vue';
   import { FilterMatchMode } from 'primevue/api';
   
   export default {
       components: {
         
           "tabla-datos": TablaDatos,
       },
       data() {
           return {
               total: '',
               expedientes: [],
               parametros: '',
               filtrosBuscador: ['codigo_servicio', 'nombre_compania', 'nombre_tramitador'],
               filtros: {
                   'codigo_servicio': { value: "", matchMode: FilterMatchMode.CONTAINS },
                   'nombre_compania': { value: "", matchMode: FilterMatchMode.CONTAINS },
                   'nombre_tramitador': { value: "", matchMode: FilterMatchMode.CONTAINS },
               },
               columnas: [
                   {
                       header: 'Tipo',
                       field: 'class',
                   },
                   { 
                       header: 'Expediente',
                       field: 'codigo_servicio',
                       sortable: false,
                       acciones: [
                           {
                               link: true,
                               tipo: 'default',
                               nombreId: 'id_servicio',
                               nombreIddos: 'codigo_servicio',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id, codigo) => {
                                   console.log('Editar expediente ' + id);
                                   localStorage.setItem('nombreservicio' + id, codigo);
                                   localStorage.setItem('pestanyactivaservicio' + id, 'comunicaciones');

                                   this.$router.push({
                                       name: 'Servicio',
                                       params: {
                                           id: id,
                                           nombreservicio: codigo,
                                       }
                                   });
                               }
                           },
                       ]
                   },
                   {
                       header: 'Compañia',
                       field: 'nombre_compania',
                   },
                   {
                       header: 'Tramitador',
                       field: 'nombre_tramitador',
                       sortable: false,
                   },
                   {
                       header: 'Fecha/Hora',
                       field: 'fecha_hora',
                       sortable: true,
                   },
                   {
                       header: 'Mensaje',    
                       field: 'texto',
                   },
                   {
                       header: 'Estado',
                       field: 'estado_servicio',
                   },
                   {
                       data: null,
                       acciones: [
                           
                           {
                               nombre: 'Eliminar',
                               class: 'eliminar',
                               tipo: 'danger',
                               nombreId: 'id_aviso',
                               nombreIddos: 'codigo_servicio',

                               icono: 'far fa-trash-alt',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id, codigo_servicio) => {
                                   if (confirm('Desea eliminar la solicitud ' + codigo_servicio)) {

                                       this.eliminarsolicitud(id);
                                   }
                               }
                           },
                       ]
                   }
               ]
           }
       },
       methods: {
           async obtenerExpedientes(event) {
               const api = new PwgsApi;
               const aux = await api.post('avisos/solicitudes', event);
               this.expedientes = aux.datos;
               this.total = aux.n_total_registros;
               this.$emit('evento_total_solicitudes', this.total); //Es llamado por ./Escritorio.vue
           },
           async eliminarsolicitud(id_aviso) {
               //put pwgsapi/index.php/avisos/revisar-avisos
               const api = new PwgsApi();
               let ids = [];
               ids.push(id_aviso);
               let subidadatos = { tipo: 'solicita', ids_avisos: ids, estado: 1, }
               await api.put('avisos/revisar-avisos', subidadatos);
               this.obtenerExpedientes();
           }
       },
       activated() {
          // this.obtenerExpedientes();
       },
           mounted() {
           //this.obtenerExpedientes();
       }
   }
   </script>
   <style>
   .p-dialog {
       width: 90%;
   }
   .p-datatable-header{
   
       visibility:hidden;
   }
   
   </style>